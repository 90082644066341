@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
    zoom: 100%;
}

body {
    box-sizing: border-box;
    height: 100%;
    font-family: poppins, serif;
    font-size: 1rem;
    background: #F7F9FF;
    zoom: 100%;
}

.img-thumbnail{
    background: transparent !important;
    border: none !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
    background: #20c997 !important;
    border-color: #20c997 !important;
    outline: none !important;
    box-shadow: none !important;
    font-size: 1rem;
    padding: 0.7rem 1rem;
}

.btn-success,
.btn-info{
    border-color: #20c997 !important;
    color: #20c997 !important;
    background: transparent;
    padding: 0.7rem 1rem;
    font-size: 1rem;
    outline: none !important;
}

.btn-success:active,
.btn-success:focus,
.btn-success:hover,
.btn-info:active,
.btn-info:focus,
.btn-info:hover {
    background: #20c997 !important;
    color: #ffffff !important;
    outline: none !important;
}

.btn-primary:disabled{
    background: rgba(32, 201, 151, 0.80) !important;
    pointer-events: none;
}

.btn-success:disabled{
    background: rgba(32, 201, 151, 0.30) !important;
    pointer-events: none;
}

nav.navbar.bg-dark {
    background: #07bb71 !important;
    padding: 0.5rem 1.5rem !important;
}

nav.navbar.bg-light {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, .15) !important;
    padding: 1rem 2rem;
}

nav.navbar.bg-light .navbar-nav {
    column-gap: 3rem;
}

.navbar-light .navbar-nav .nav-link {
    color: #222222 !important;
    font-weight: 600 !important;
    border-bottom: 2px solid transparent !important;
    border-radius: 0 !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.nav-active {
    color: #5e72e4 !important;
    background-color: transparent !important;
    border-bottom: 2px solid #5e72e4 !important;
}

.user-dropdown a.dropdown-toggle div {
    background: #f7f7f7 !important;
}

.user-dropdown a.dropdown-toggle p {
    color: #222222 !important;
    font-weight: 500;
    line-height: 1 !important;
}

.container-fluid > .row {
    row-gap: 1rem;
    margin-bottom: 2rem !important;
}

.container-fluid > .row .card .card-body,
.row > .align-items-center .card .card-body {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.container-fluid > .row .card .card-body div.h5,
.row > .align-items-center .card .card-body div.h5 {
    color: #222222;
    font-size: 1.1rem;
    white-space: nowrap;
}

.container-fluid > .row .card .card-body div.h5{
    margin-bottom: 0;
}

.container-fluid > .row .card .card-body .mb-3,
.row > .align-items-center .card .card-body .mb-3 {
    margin-bottom: 0 !important;
}

.container-fluid > .row .card .card-body .mb-3 .basic-multi-select {
    margin-top: 0 !important;
}

.container-fluid > .row .card .card-body > div:not(.h5),
.row > .align-items-center .card .card-body > div:not(.h5) {
    width: 100%;
}

.container-fluid > .row .card .card-body .row .col {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
}

.card-header h3 {
    font-size: 1.5rem;
}

.card-header > div {
    display: flex;
    gap: 1rem;
}

.fixed-table-toolbar .btn-group {
    display: none;
}


.react-bootstrap-table table {
    font-size: 1rem !important;
}

.react-bootstrap-table table thead th {
    background: #04AA6D !important;
    color: #f2f2f2 !important;
    padding: 1rem;
    font-size: 1rem;
}

.react-bootstrap-table table tr:hover td {
    background: #eaeaea !important;
}

.react-bootstrap-table table tr td {
    font-size: 1rem !important;
}

.react-bootstrap-table table tr td button,
.react-bootstrap-table table tr td a button,
.react-bootstrap-table table tr td a.btn {
    padding: 0.8rem 1.3rem !important;
}

.react-bootstrap-table table tr td button svg,
.react-bootstrap-table table tr td a button svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
}

.react-bootstrap-table table tr td .btn-actions {
    column-gap: 0.5rem;
}

.badge {
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    width: 130px;
}

.react-bootstrap-table-pagination-total {
    font-size: 1.2rem;
}

ul.pagination {
    column-gap: 0.8rem;
}

ul.pagination li {
    border-radius: 0 !important;
    border: none !important;
    width: auto !important;
}

ul.pagination li a {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #222222;
    width: 50px;
    height: 50px;
    color: #222222 !important;
    border-radius: 0.5rem !important;
}

ul.pagination li:hover,
ul.pagination li.active {
    background: #04AA6D;
    border-radius: 0.5rem !important;
}

ul.pagination li:hover a,
ul.pagination li.active a {
    border-color: #04AA6D !important;
    color: #ffffff !important;
}

ul.pagination li.break-me a,
ul.pagination li.previous.disabled a,
ul.pagination li.next.disabled a {
    background: #e2e6ea !important;
    border-color: #dae0e5 !important;
    color: #21252996 !important;
    pointer-events: none;
}

.card .height-full .btn-primary,
.modal .modal-body .height-full .btn-primary,
.card .height-full .btn-info,
.modal .modal-body .height-full .btn-info {
    width: 100% !important;
    text-align: center !important;
}

.card .height-full .btn-primary:hover,
.modal .modal-body .height-full .btn-primary:hover,
.card .height-full .btn-info,
.modal .modal-body .height-full .btn-info {
    background: transparent !important;
    color: #20c997 !important;
}

.card .height-full .btn-info,
.modal .modal-body .height-full .btn-info {
    border-color: #20c997 !important;
    outline: none !important;
    box-shadow: none !important;
}

.modal .modal-content {
    border-radius: 2rem;
    padding: 1rem;
}

.modal .modal-header .close {
    font-size: 2.5rem;
}

.modal .modal-header .modal-title.h4 {
    font-size: 1.5rem !important;
    color: #555555;
    font-weight: 600;
}

.modal .modal-body .row.mb-3 {
    border: 1px solid #20c997;
    border-radius: 1rem;
}

.modal .modal-body .row.mb-3 .col-lg-4.col-md-4.col-sm-12 {
    border-radius: 1rem;
    font-weight: 600;
}

.modal .modal-body .row.mb-3 .col-lg-4.col-md-4.col-sm-12.nav-active,
.modal .modal-body .row.mb-3 .col-lg-4.col-md-4.col-sm-12:hover {
    background: #20c997 !important;
    color: #ffffff;
}

.modal .modal-footer button {
    font-size: 1rem;
    padding: 0.6rem 1.5rem !important;
}

.modal .modal-footer button:not(.btn-success),
.modal .modal-footer button:hover,
.modal .modal-footer button:focus,
.modal .modal-footer button:active {
    background: #20c997 !important;
    border-color: #20c997 !important;
    outline: none !important;
    box-shadow: none !important;
    color: #ffffff !important;
}

.rbc-calendar .rbc-toolbar .rbc-btn-group button {
    padding: 0.6rem 1.4rem;
}

.card-header-pills .side-tab-list-item {
    margin-bottom: 0.5rem !important;
}

.card-header-pills .side-tab-list-item:last-child {
    margin-bottom: 0 !important;
}

.card-header-pills .side-tab-list-item a {
    background: transparent !important;
    color: #222222 !important;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
}

.card-header-pills .side-tab-list-item a:hover,
.card-header-pills .side-tab-list-item a.active {
    color: #ffffff !important;
    background-color: #20c997 !important;
}

.tab-content .tab-pane .card-header strong,
.tab-content .tab-pane .card-header h4 {
    color: #555555 !important;
    font-size: 1.5rem !important;
}

.tab-content .tab-pane .card-header a {
    padding: 0.6rem 1.4rem;
}

.tab-content .tab-pane .card-header .btn-warning {
    background: transparent !important;
    border-color: #20c997 !important;
    color: #20c997 !important;
    height: 100%;
}

.tab-content .tab-pane .card-header a svg {
    height: 1.2rem;
    width: 1.2rem;
}

.tab-content .tab-pane .card-header .btn-warning:hover {
    background: #20c997 !important;
    color: #ffffff !important;
}

.card-body .collapse .card-body {
    flex-direction: column;
    align-items: start !important;
}

.card-body .collapse .card-body .w-75 {
    order: 2;
}

.card-body .collapse .card-body .d-flex {
    order: 1;
}

.card-body .collapse .card-body .d-flex button {
    background: transparent !important;
    border-color: #20c997 !important;
    color: #20c997 !important;
    padding: 0.8rem 2rem;
}

.card-body .collapse .card-body .d-flex button:hover {
    background: #20c997 !important;
    color: #ffffff !important;
}

.card-body .collapse .card-body .d-flex button svg {
    height: 1.2rem;
    width: 1.2rem;
}

.container-fluid > .row .col-lg-7.col-md-7.col-12 .card .card-body,
    .container-fluid > .row .col-lg-5.col-md-5.col-12 .card .card-body {
    display: block !important;
}

.container-fluid .card .card-header a{
    background: #20c997 !important;
    color: #ffffff !important;
    border-color: #20c997 !important;
    outline: none !important;
    box-shadow: none !important;
}

.container-fluid .card .card-header a.btn-dark{
    background: transparent !important;
    color: #20c997 !important;
}

.user-dropdown .dropdown-menu .dropdown-item{
    border-bottom: 0 !important;
}

.user-dropdown a:active,
    .user-dropdown a:hover{
    background-color: #eaeaea;
    color: #222222;
}
iframe {
    display: none !important;
}